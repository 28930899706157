import React from "react";
import "./css/index.css";
import { ResdiencyType, ResidencyListType } from "resdiency";
import ResidencyDetailContainer from "../../components/ResidencyDetail/container/ResidencyDetailContainer";
import ResidencySliderContainer from "../../components/ResidencySlider/container/ResidencySliderContainer";

type Props = {
  data: ResdiencyType;
  changeImg: ResidencyListType;
  NavList: () => void;
};

const ResidencyMobile = ({ data, changeImg, NavList }: Props) => {
  return (
    <main className="residency-mobile-page">
      <ResidencyDetailContainer
        name={data.name}
        subject={data.category}
        data={data?.list}
        changeImg={changeImg}
        CloseModal={NavList}
      />
      <div className="resdiency-info-wrapper">
        <div className="glasses-wrapper"></div>
        <div className="author-wrapper">
          <p className="author-name">{data.name}</p>
          <div className="author-description">{data.subject}</div>
        </div>
      </div>
    </main>
  );
};

export default ResidencyMobile;
