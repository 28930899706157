import "./css/index.css";
import { UserAgentType } from "common";
import BannerContainer from "../../components/Banner/container/BannerContainer";
import AboutCardContainer from "../../components/AboutCard/container/AboutCardContainer";
import Section02 from "./components/Section02/Section02";
import Section03 from "./components/Section03/Section03";
import Section01 from "./components/Section01/Section01";
import { ResdiencyType } from "resdiency";

type Props = {
  currentIdx: null | number;
  userAgent: UserAgentType;
  SectionOpen: (idx: number) => void;
  regidency: ResdiencyType[];
};

const About = ({ currentIdx, userAgent, SectionOpen, regidency }: Props) => {
  const aboutCardArr = [
    {
      title: "EXHIBITION PLANNING",
      subTitle: "전시 및 행사 기획",
    },
    {
      title: "ARTIST MANAGEMENT",
      subTitle: "아티스트 매니지먼트",
    },
    {
      title:
        userAgent !== "mb"
          ? "ART TRADE"
          : `ART 
TRADE`,
      subTitle: "작품 구매",
    },
  ];

  return (
    <main className="about-page">
      <BannerContainer userAgent={userAgent} />
      {aboutCardArr.map(({ title, subTitle }, idx) => (
        <div key={idx} className="section-wrapper">
          <AboutCardContainer
            currentIdx={currentIdx}
            title={title}
            subTitle={subTitle}
            idx={idx}
            SectionOpen={SectionOpen}
          />
          <div
            className={`section-slide ${
              currentIdx === idx ? "active" : "hide"
            }`}
          >
            {currentIdx === idx &&
              (idx === 0 ? (
                <Section01 userAgent={userAgent} />
              ) : idx === 1 ? (
                <Section02 userAgent={userAgent} regidency={regidency} />
              ) : (
                idx === 2 && <Section03 userAgent={userAgent} />
              ))}
          </div>
        </div>
      ))}
    </main>
  );
};

export default About;
