import CryptoJs from "crypto-js";

export const formatPhoneNumber = (value: string) => {
  // 숫자만 남기기
  const cleaned = value.replace(/\D/g, "");

  // 휴대폰 번호 포맷팅
  const formatted = cleaned.replace(
    /(\d{3})(\d{3,4})?(\d{4})?/,
    (_, p1, p2, p3) => {
      let result = p1;
      if (p2) result += `-${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    }
  );

  return formatted;
};

export const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);

  // 년/월/일 형식으로 변환
  const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;

  return formattedDate;
};

export const encrypto = (data: string) => {
  return CryptoJs.AES.encrypt(
    data,
    String(process.env.REACT_APP_KEY)
  ).toString();
};

export const decypto = (data: string) => {
  try {
    const bite = CryptoJs.AES.decrypt(data, String(process.env.REACT_APP_KEY));
    const decryptedData = bite.toString(CryptoJs.enc.Utf8);
    return decryptedData;
    // JSON 형식이 맞는지 확인
  } catch (err) {
    console.error("Decryption error:", err);
    return;
  }
};
