import React from "react";
import "./css/index.css";
import AboutImg from "../../../../components/AboutImg/AboutImg";
import { UserAgentType } from "common";
import ListCard from "../../../../components/ListCard/ListCard";
import DisplayImg from "../../../../components/DisplayImg/DisplayImg";
import AboutInfoCard from "../../../../components/common/AboutInfoCard/AboutInfoCard";
import { Link } from "react-router-dom";

type Props = {
  userAgent: UserAgentType;
};

const Section01 = ({ userAgent }: Props) => {
  const imgArr = ["section1-1", "section1-2", "section1-3"];
  const listArr = [
    {
      title: "대상자",
      lists: [
        {
          title:
            userAgent === "pc"
              ? `개인전, 단체전, 특별전, 기획전 등 목적을 가진 <strong>전시를 진행하고자</strong> 하는 개인 및 단체`
              : userAgent === "tablet"
              ? `개인전, 단체전, 특별전, 기획전 등 목적을 가진 <strong>전시를 진행</strong>하고자 
    하는 개인 및 단체`
              : `개인전, 단체전, 특별전, 기획전 등 목적을 가진 
     <strong>전시를 진행하고자</strong> 하는 개인 및 단체`,
        },
        {
          title:
            userAgent === "pc"
              ? `작품을 통해 도심의 유휴공간, 낙후공간 등을 <strong>문화도시공간으로</strong> 변화하고자 하는 개인 및 단체`
              : userAgent === "tablet"
              ? `작품을 통해 도심의 유휴공간, 낙후공간 등을 <strong>문화도시공간으로</strong> 변화하고
    자 하는 개인 및 단체`
              : `작품을 통해 도심의 유휴공간, 낙후공간 등을 
     <strong>문화도시공간으로</strong> 변화하고자 하는 개인 및 단체`,
        },
        {
          title:
            userAgent === "pc"
              ? `일상 속 예술이 자리매김할 수 있도록 <strong>미술교육 프로그램</strong>을 진행하고자 하는 개인 및 단체`
              : userAgent === "tablet"
              ? `일상 속 예술이 자리매김할 수 있도록 <strong>미술교육 프로그램</strong>을 진행하고자
    하는 개인 및 단체`
              : `일상 속 예술이 자리매김할 수 있도록 <strong>미술교육 
     프로그램</strong>을 진행하고자 하는 개인 및 단체`,
        },
        {
          title:
            userAgent === "pc" || userAgent === "tablet"
              ? "예술을 통한 <strong>ESG경영 가치를 실현</strong>하고자 하는 기업 및 단체"
              : `예술을 통한 <strong>ESG경영 가치를 실현</strong>하고자 하는 
     기업 및 단체`,
        },
      ],
    },
    {
      title: "전시 및 행사 기획 과정",
      lists: [
        {
          title:
            userAgent === "pc" || userAgent === "tablet"
              ? `<strong>사전 미팅</strong> : 전시 및 행사의 취지, 목적, 산출물 등 전반에 대한 사전 미팅 진행`
              : `<strong>사전 미팅</strong> : 전시 및 행사의 취지, 목적, 산출물 등 
                      전반에 대한 사전 미팅 진행`,
        },
        {
          title:
            userAgent === "pc"
              ? "<strong>기획 제안</strong> : 프로젝트의 목적에 맞는 장소 선정, 전시 매체 방식 등 전시 기획 진행 및 제안"
              : userAgent === "tablet"
              ? `<strong>기획 제안</strong> : 프로젝트의 목적에 맞는 장소 선정, 전시 매체 방식 등 전시 기획 
                      진행 및 제안`
              : userAgent === "mb" &&
                `<strong>기획 제안</strong> : 프로젝트의 목적에 맞는 장소 선정, 전시
                      매체 방식 등 전시 기획 진행 및 제안`,
        },
        {
          title: "<strong>협의된 기획서를 기반으로 전시 및 행사 운영</strong>",
        },
      ],
    },
  ];

  const displayImg = [
    {
      img: "display1-1",
      title: "전시 기획 및 운영",
      subtitle: `작가 개인 및 단체를 대상으로
작품 전시를 위한 기획 및 운영`,
    },
    {
      img: "display1-2",
      title: "국제 전시 및 행사 운영",
      subtitle: `비엔날레, 트리엔날레 등
정기적으로 운영되는 전시 행사`,
    },
    {
      img: "display1-3",
      title: "도시문화공산재생",
      subtitle: `도시문화공간 재생을 위한
기획 및 설치`,
    },
    {
      img: "display1-4",
      title: "미술교육 프로그램",
      subtitle: `작가의 작업 세계관을
미술교육 커리큘럼으로 제작한 프로그램 진행`,
    },
  ];

  const titleArr = {
    title: "전시 및 행사 기획",
    mainTitle:
      userAgent === "pc" || userAgent === "tablet"
        ? `대중과 예술을
이어 주다.`
        : userAgent === "mb" && `대중과 예술을 이어 주다.`,
    subtitle:
      userAgent === "pc"
        ? `kunst EINS는 예술을 가장 가깝게 만날 수 있는 방법을 고민하고,
의미 있는 경험을 제공할 수 있는 방식을 연구합니다.

우리는 글로벌 트렌드를 이끄는 ‘예술 콘텐츠’와
우리의 고객인 ‘대중’을 최우선 가치로 두고,
높은 기준과 끊임없는 개선으로 더 나은 전시와 예술 행사를 위해
다양한 기획과 운영을 책임집니다.
`
        : userAgent === "tablet"
        ? `kunst EINS는 예술을 가장 가깝게 
만날 수 있는 방법을 고민하고,
의미 있는 경험을 제공할 수 있는 
방식을 연구합니다.

우리는 글로벌 트렌드를 이끄는
‘예술 콘텐츠’와 우리의 고객인
‘대중’을 최우선 가치로 두고,
높은 기준과 끊임없는 개선으로 더 
나은 전시와 예술 행사를 위해
다양한 기획과 운영을 책임집니다.
`
        : `kunst EINS는 예술을 가장 가깝게 만날 수 있는 방법을
고민하고, 의미 있는 경험을 제공할 수 있는 방식을
연구합니다. 우리는 글로벌 트렌드를 이끄는
‘예술 콘텐츠’와 우리의 고객인 ‘대중’을 최우선 가치로 두고,
높은 기준과 끊임없는 개선으로 더 나은 전시와
예술 행사를 위해 다양한 기획과 운영을 책임집니다.
`,
  };

  return (
    <div className={`about-section01`}>
      <div className="about-img-wrapper">
        {imgArr.map((item, idx) => (
          <AboutImg userAgent={userAgent} src={item} key={idx} />
        ))}
      </div>
      <div className="display-wrapper">
        <AboutInfoCard
          title={titleArr.title}
          mainTitle={titleArr.mainTitle}
          subtitle={titleArr.subtitle}
        />
        <div className="right-wrapper">
          {listArr.map((item, idx) => (
            <ListCard key={idx} title={item.title} lists={item.lists} />
          ))}
          <div className="display-img-wrapper">
            <div className="img-title-wrapper">
              <p className="img-title">전시 및 행사 예시</p>
            </div>
            <div className="img-cards">
              <div className="img-box">
                {displayImg.map(({ img, title, subtitle }, idx) => (
                  <DisplayImg
                    key={idx}
                    title={title}
                    img={img}
                    subtitle={subtitle}
                    userAgent={userAgent}
                  />
                ))}
              </div>
              <div className="button-wrapper">
                <button className="button">
                  <Link className="btn-text" to={"/project"}>
                    더 많은 프로젝트 보러가기
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section01;
