import React from "react";
import "./css/index.css";
import ProjectTitle from "../../components/ProjectTitle/ProjectTitle";
import SliderBannerContainer from "../../components/common/SliderBanner/container/SliderBannerContainer";
import { UserAgentType } from "common";
import ProjectImgCard from "../../components/ProjectImgCard/ProjectImgCard";
import PageSelectorContainer from "../../components/common/PageSelector/container/PageSelectorContainer";
import { ProjectType } from "projectus";
import { Banner } from "banner";

type Props = {
  userAgent: UserAgentType;
  totalItems: number;
  data: ProjectType[];
  currentPage: number;
  banner: Banner[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  ProjectNavigate: (data: ProjectType) => void;
};
const Project = ({
  userAgent,
  totalItems,
  data,
  banner,
  setCurrentPage,
  ProjectNavigate,
  currentPage,
}: Props) => {
  return (
    <main className="project-page">
      <section className="now-section">
        <ProjectTitle fahkwang="Now" text="진행 중인 프로젝트" />
        <div className="slider-banner-wrapper">
          <SliderBannerContainer data={banner} />
        </div>
      </section>
      <section className="project-section">
        <div className="search-wrapper">
          <ProjectTitle fahkwang="All Project" text="전체 프로젝트" />
          <div className="search-bar">
            <input
              type="text"
              name="search"
              onChange={() => {}}
              className="search-input"
            />
            <button className="search-btn">
              <img
                src={`/assets/project/${userAgent}/search.png`}
                srcSet={`
                /assets/project/${userAgent}/search@2x.png 2x,
                /assets/project/${userAgent}/search@3x.png 3x,
              `}
                alt="search"
              />
            </button>
          </div>
        </div>
        <div className="all-project-imgs">
          {data.slice(currentPage * 6, currentPage * 6 + 6).map((item, idx) => (
            <ProjectImgCard
              key={idx}
              ProjectNavigate={ProjectNavigate}
              data={item}
            />
          ))}
        </div>
        <div className="page-selector-wrapper">
          <PageSelectorContainer
            currentPage={currentPage}
            totalItems={totalItems}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </main>
  );
};

export default Project;
