import React, { useCallback, useEffect, useRef, useState } from "react";
import "../css/index.css";
import ResidencyMobile from "../ResidencyMobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ResdiencyType, ResidencyListType } from "resdiency";
import { get, ref } from "firebase/database";
import { db } from "../../../core/firebase";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { UserAgentType } from "common";

type Props = {
  userAgent: UserAgentType;
};
const ResidencyMobileContainer = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const changeImg = useSelector<RootState, ResidencyListType | undefined>(
    (state) => state.config.img
  );
  const [data, setdata] = useState<ResdiencyType | null>(null);

  const NavList = useCallback(() => {
    navigate("/Residency");
  }, []);

  useEffect(() => {
    if (userAgent !== "mb") {
      navigate("/residency");
    }
    if (id) {
      get(ref(db, `/regidency/${id}`)).then((res) => {
        if (res.exists()) {
          setdata(res.val());
        }
      });
    }
    return () => {};
  }, [id, userAgent]);

  if (data && changeImg) {
    return (
      <ResidencyMobile data={data} changeImg={changeImg} NavList={NavList} />
    );
  }
  return <></>;
};

export default ResidencyMobileContainer;
