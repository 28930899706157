import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
type Props = {
  userAgent: UserAgentType;
};
const Banner = ({ userAgent }: Props) => {
  return (
    <div className="aboutus-banner">
      <p className="aboutus-banner-title">
        {`우리의 생각이 세상을 바꿀 수 
있다고 믿습니다.`}
      </p>
      <p className="aboutus-banner-subtitle">{`쿤스트아인스의 비즈니스는 지속가능성과 
문화·예술의 가치를 높이는 기획, 프로그램, 공간 
그리고 브랜드를 만드는 서비스를 제공합니다.`}</p>
      <div className="line-container">
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line5"></div>
      </div>
    </div>
  );
};

export default Banner;
