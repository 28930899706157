import React, { useCallback, useEffect, useRef, useState } from "react";
import ResidencyDetail from "../ResidencyDetail";
import { ResidencyListType } from "resdiency";
import { encrypto } from "../../../lib/lib";
type Props = {
  data: {
    caption: string;
    img: string;
    timestamp: number;
    title: string;
  }[];
  id?: string;
  name: string;
  subject: string;
  changeImg: ResidencyListType;
  CloseModal?: () => void;
};

const ResidencyDetailContainer = ({
  name,
  id,
  subject,
  data,
  changeImg,
  CloseModal,
}: Props) => {
  const [ratio, setRatio] = useState<number>(1);

  const ImagePlus = useCallback(() => {
    setRatio((prevRatio) => Math.min(10, prevRatio + 0.25)); // 최대 2배까지 확대
  }, [ratio]);

  const ImageMinus = useCallback(() => {
    let newRatio = ratio > 4 ? 1 : 0.25;
    setRatio((prevRatio) => Math.max(0.1, prevRatio - newRatio)); // 최소 0.5배까지 축소
  }, [ratio]);

  const ShareLink = useCallback(async () => {
    const key = await encrypto(changeImg.timestamp.toString());
    const ids = await encrypto(String(id));
    navigator.clipboard.writeText(
      `https://kunsteins.com//Residency?time=${encodeURIComponent(
        key
      )}&id=${encodeURIComponent(ids)}`
    );
    alert("링크가 복사되었습니다.");
  }, [changeImg, id]);

  return (
    <ResidencyDetail
      data={data}
      name={name}
      subject={subject}
      changeImg={changeImg}
      ratio={ratio}
      ImagePlus={ImagePlus}
      ImageMinus={ImageMinus}
      CloseModal={CloseModal}
      ShareLink={ShareLink}
    />
  );
};

export default ResidencyDetailContainer;
