import { UserAgentType } from "common";
import "./css/index.css";
import React from "react";
import { ResdiencyType } from "resdiency";
import { Link } from "react-router-dom";
type Props = {
  data: ResdiencyType;
  userAgent: UserAgentType;
};
const AuthorImgCard = ({ userAgent, data }: Props) => {
  return (
    <Link className="author-img-card" to={"/residency"} state={data}>
      {data.list && (
        <img src={data.list[0].resize || data.list[0].img} alt="author-img" />
      )}
      <div className="background-gradient" />
      <div className="author-button-wrapper">
        <p className="author-name">{data.name}</p>
        <div className="arrow-btn">
          <img src="/assets/about/arrow.svg" alt="arrow-btn" />
        </div>
      </div>
    </Link>
  );
};

export default AuthorImgCard;
