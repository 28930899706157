import React from "react";
import "./css/index.css";
import ContactusInput from "../../components/ContactusInput/ContactusInput";
import { UserAgentType } from "common";
import { ContactusType } from "contactus";
type Props = {
  userAgent: UserAgentType;
  currentBtn: number | null;
  agreement: boolean;
  allValuesFiled: boolean;
  data: ContactusType;
  InquiryBtnClicked: (idx: number, category: string) => void;
  UpdateData: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  AgreementClicked: () => void;
  Submit: () => void;
};
const ContactUs = ({
  userAgent,
  currentBtn,
  agreement,
  allValuesFiled,
  data,
  InquiryBtnClicked,
  AgreementClicked,
  UpdateData,
  Submit,
}: Props) => {
  const inputArr = [
    {
      label: "이름",
      name: "name",
      type: "text",
      value: data.name,
      maxLength: 6,
    },
    {
      label: "이메일",
      name: "email",
      type: "email",
      value: data.email,
      maxLength: 30,
    },
    {
      label: "연락처",
      name: "tel",
      type: "text",
      value: data.tel,
      maxLength: 13,
    },
  ];
  return (
    <main className="contactus-page">
      <section className="title-section">
        <div className="title-wrapper">
          <p className="contactus-title fahkwang-text">Contact us</p>
          <p className="contactus-subtitle">
            궁금한 점이 있다면 언제든지 문의 부탁드립니다
          </p>
        </div>
      </section>
      <section className="contactus-section">
        <div className="contactus-wrapper">
          {contactusArr.map(({ img, title }, idx) => {
            if (img === "insta") {
              return (
                <a
                  className="contactus-box"
                  key={idx}
                  href="https://www.instagram.com/kunst_eins/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="contactus-img"
                    src={`/assets/common/${img}.png`}
                    srcSet={`
                  /assets/common/${img}@2x.png 2x,
                  /assets/common/${img}@3x.png 3x,
                `}
                    alt="contactus-img"
                  />
                  <p className="contactus-title">{title}</p>
                </a>
              );
            }
            return (
              <div className="contactus-box" key={idx}>
                <img
                  className="contactus-img"
                  src={`/assets/common/${img}.png`}
                  srcSet={`
                  /assets/common/${img}@2x.png 2x,
                  /assets/common/${img}@3x.png 3x,
                `}
                  alt="contactus-img"
                />
                <p className="contactus-title">{title}</p>
                {img === "call" && (
                  <div className="call-box">
                    <a href="tel:010-2713-0421">전화문의</a>
                  </div>
                )}
              </div>
            );
          })}
          <a
            className="kakao-ch-btn"
            href="http://pf.kakao.com/_Aaxlln"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/common/kakao.png"
              srcSet={`
                /assets/common/kakao@2x.png 2x,
                /assets/common/kakao@3x.png 3x,
              `}
              alt="kakao-ch"
            />
            <p className="kakao-text">카카오 채널 바로가기</p>
          </a>
        </div>
      </section>
      <section className="input-section">
        {/* 문의종류 */}
        <div className="inquiry-type-wrapper">
          <p className="inquiry-text">문의종류</p>
          <div className="btn-wrapper">
            {inquiryBtnArr.map(({ title }, idx) => (
              <button
                onClick={() => InquiryBtnClicked(idx, title)}
                className="inquiry-btn"
                key={idx}
                style={{
                  backgroundColor: currentBtn === idx ? "#0000001A" : "",
                }}
              >
                <div
                  className="check-circle"
                  style={{
                    backgroundColor: currentBtn === idx ? "black" : "",
                  }}
                >
                  {currentBtn === idx && (
                    <img
                      src="/assets/common/check.png"
                      srcSet="
                        /assets/common/check@2x.png 2x,
                        /assets/common/check@3x.png 3x,
                      "
                      alt=""
                    />
                  )}
                </div>
                <p className="btn-title">{title}</p>
              </button>
            ))}
          </div>
        </div>
        {/* 문의 내용입력 */}
        <div className="inquiry-input-wrapper">
          <div className="input-wrapper">
            <div className="left-box">
              {inputArr.map(({ label, name, type, value, maxLength }, idx) => (
                <ContactusInput
                  label={label}
                  name={name}
                  type={type}
                  value={value}
                  key={idx}
                  maxLength={maxLength}
                  UpdateData={UpdateData}
                />
              ))}
              {userAgent !== "mb" && (
                <button onClick={AgreementClicked} className="agreement-btn">
                  <div
                    className="check-circle"
                    style={{
                      backgroundColor: agreement ? "black" : "",
                    }}
                  >
                    {agreement && (
                      <img
                        src="/assets/common/check.png"
                        srcSet="
                          /assets/common/check@2x.png 2x,
                          /assets/common/check@3x.png 3x,
                        "
                        alt=""
                      />
                    )}
                  </div>
                  <p>상담을 위한 개인정보 사용에 동의합니다</p>
                </button>
              )}
            </div>
            <div className="right-box">
              <label htmlFor="description" className="description-text">
                문의 내용
              </label>
              <textarea
                placeholder="내용을 입력해주세요"
                name="content"
                className="description"
                value={data.content}
                onChange={UpdateData}
              />
              {userAgent === "mb" && (
                <button className="agreement-btn" onClick={AgreementClicked}>
                  <div
                    className="check-circle"
                    style={{
                      backgroundColor: agreement ? "black" : "",
                    }}
                  >
                    {agreement && (
                      <img
                        src="/assets/common/check.png"
                        srcSet="
                          /assets/common/check@2x.png 2x,
                          /assets/common/check@3x.png 3x,
                        "
                        alt=""
                      />
                    )}
                  </div>
                  <p>상담을 위한 개인정보 사용에 동의합니다</p>
                </button>
              )}
              <button
                onClick={Submit}
                style={{
                  backgroundColor:
                    currentBtn && agreement && allValuesFiled
                      ? "black"
                      : "#0000001A",
                }}
                disabled={
                  allValuesFiled && currentBtn && agreement ? false : true
                }
                className="submit-btn"
              >
                S E N D
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;

const contactusArr = [
  {
    img: "mail",
    title: "kunsteins.kr@gmail.com",
  },
  {
    img: "call",
    title: "010-2713-0421",
  },
  {
    img: "insta",
    title: "@kunst_eins",
  },
];

const inquiryBtnArr = [
  {
    title: "전시 및 행사 기획",
  },
  {
    title: "아티스트 매니지먼트",
  },
  {
    title: "작품 구매",
  },
  {
    title: "기타 문의",
  },
];
