import { ResidencyViewType } from "resdiency";
import "./css/index.css";
import { UserAgentType } from "common";

type Props = {
  src: string;
  name: string;
  data: ResidencyViewType;
  selectedIdx: number | null;
  idx: number;
  category: string;
  userAgent: UserAgentType;
  CardClicked: (data: ResidencyViewType, idx: number) => void;
  imgMouseOver: (data: ResidencyViewType, idx: number) => void;
};
const ResidencyCard = ({
  src,
  name,
  idx,
  selectedIdx,
  data,
  category,
  userAgent,
  CardClicked,
  imgMouseOver,
}: Props) => {
  return (
    <div
      className="residency-card"
      onMouseOver={() => imgMouseOver(data, idx)}
      onClick={() => CardClicked(data, idx)}
    >
      <img className="residency-card-img" src={src} alt="" loading="lazy" />
      {(selectedIdx === idx || userAgent === "mb") && (
        <div className="background-gradient" />
      )}
      {userAgent !== "mb" ? (
        <div className="author-info">
          <p className="author-name">{selectedIdx === idx && name + "작가"}</p>
          <p className="author-subtitle">{selectedIdx === idx && category}</p>
        </div>
      ) : (
        userAgent === "mb" && (
          <div className="author-info">
            <p className="author-name">{data.name + "작가"}</p>
            <p className="author-subtitle">{data.category}</p>
          </div>
        )
      )}
      {userAgent !== "mb" && selectedIdx === idx && (
        <button className="detail-btn">detail</button>
      )}
      {userAgent === "mb" && <button className="detail-btn">detail</button>}
    </div>
  );
};

export default ResidencyCard;
