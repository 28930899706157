import { UserAgentType } from "common";
import "./css/index.css";
import { Link } from "react-router-dom";
type Props = {
  userAgent: UserAgentType;
  isMenuOpen: boolean;
  MenuOpenClicked: () => void;
};
const Header = ({ userAgent, isMenuOpen, MenuOpenClicked }: Props) => {
  return (
    <header className="header">
      <Link className="logo-img" to={"/"}>
        <img
          src="/assets/header/logo.png"
          srcSet="
              /assets/header/logo@2x.png 2x,
              /assets/header/logo@3x.png 3x,
            "
          alt="logo"
        />
      </Link>
      {userAgent === "pc" ? (
        <div className="menu-list-wrapper">
          {menuList.map(({ title, link }, idx) => (
            <Link className="list fahkwang-text" to={link} key={idx}>
              {title}
            </Link>
          ))}
        </div>
      ) : !isMenuOpen ? (
        <button onClick={MenuOpenClicked}>
          <img src="/assets/header/hamburger.svg" alt="hamburger-bar" />
        </button>
      ) : (
        isMenuOpen && (
          <button onClick={MenuOpenClicked}>
            <img src="/assets/header/close.svg" alt="" />
          </button>
        )
      )}
      {isMenuOpen && (
        <div className="menu-wrapper">
          {menuList.map(({ title, link }, idx) => (
            <div className="menu-lists">
              <Link to={link} className="menu-list" key={idx}>
                {title}
              </Link>
            </div>
          ))}
          <div className="background-section" onClick={MenuOpenClicked}></div>
        </div>
      )}
    </header>
  );
};

export default Header;

const menuList = [
  {
    title: "About us",
    link: "/about",
  },
  {
    title: "Project",
    link: "/project",
  },
  {
    title: "Residency",
    link: "Residency",
  },
  {
    title: "News",
    link: "/news",
  },
  {
    title: "Contact us",
    link: "/contactus",
  },
];
