import React from "react";
import "./css/index.css";
import { ResidencyListType } from "resdiency";

type Props = {
  data: {
    caption: string;
    img: string;
    timestamp: number;
    title: string;
    resize?: string;
  }[];
  name: string;
  subject: string;
  slideWidth: number;
  sliderBoxRef: any;
  imgRef: any;
  crtImg?: ResidencyListType;
  currentSlide: number;
  PrevBtn: () => void;
  ImgClicked: (img: ResidencyListType) => void;
  NextBtn: () => void;
};

const ResidencySlider = ({
  data,
  sliderBoxRef,
  imgRef,
  currentSlide,
  name,
  subject,
  slideWidth,
  crtImg,
  ImgClicked,
  PrevBtn,
  NextBtn,
}: Props) => {
  return (
    <div className="residency-slider">
      <div className="author-wrapper">
        <p className="author">{name}</p>
        <p className="field">{subject}</p>
      </div>
      <div className="slider-wrapper">
        <div className="slider">
          <div
            className="slider-img-box"
            ref={sliderBoxRef}
            style={{
              transform: `translateX(-${currentSlide * slideWidth}px)`,
              transition: "transform 0.3s ease-in-out",
            }}
          >
            {data.map((item, idx) => (
              <button onClick={() => ImgClicked(item)}>
                <img
                  className="img"
                  ref={idx === 0 ? imgRef : null}
                  key={idx}
                  src={item.resize || item.img}
                  style={{
                    border: `${
                      item.img === crtImg?.img ? "2px solid black" : ""
                    }`,
                  }}
                  alt={`Slide ${idx}`}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="slider-btn">
          <button
            className="prev-btn btn"
            onClick={PrevBtn}
            disabled={currentSlide === 0}
          >
            <img src={"/assets/common/left-arrow.svg"} alt="left-arrow" />
          </button>
          <button
            className="next-btn btn"
            onClick={NextBtn}
            disabled={currentSlide === data.length - 1}
          >
            <img src={"/assets/common/right-arrow.svg"} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResidencySlider;
