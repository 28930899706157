import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
import ResidencySidebar from "../../components/ResidencySidebar/ResidencySidebar";
import { ResdiencyType, ResidencyListType, ResidencyViewType } from "resdiency";
import ResidencyCard from "../../components/ResidencyCard/ResidencyCard";
import ResidencyDetailContainer from "../../components/ResidencyDetail/container/ResidencyDetailContainer";
import { Link } from "react-router-dom";
import Infinite from "../../components/Infinite/Infinite";

type Props = {
  userAgent: UserAgentType;
  data: ResidencyViewType[];
  currentData: ResdiencyType;
  selectedIdx: number | null;
  page: number;
  changeImg?: ResidencyListType;
  imgMouseOver: (data: ResidencyViewType, idx: number) => void;
  ImgCardClicked: (data: ResidencyViewType, idx: number) => void;
  CloseModal: () => void;
  PagingUpdate: () => void;
};
const Residency = ({
  ImgCardClicked,
  userAgent,
  data,
  currentData,
  selectedIdx,
  page,
  CloseModal,
  changeImg,
  imgMouseOver,
  PagingUpdate,
}: Props) => {
  return (
    <main className="residency-page">
      <section className="residency-section">
        {userAgent !== "mb" && (
          <div className="left-wrapper">
            <ResidencySidebar
              name={currentData.name}
              description={currentData.subject}
            />
          </div>
        )}
        <div className="right-wrapper">
          <div className="title-wrapper">
            <p className="title fahkwang-text">Residency</p>
          </div>
          {userAgent === "mb" && (
            <div className="purchase-btn-box">
              <Link to={"/contactus"} className="purchase-btn">
                작품 구매 문의
              </Link>
            </div>
          )}
          <div className="img-card-wrapper">
            {data.slice(0, page * 9).map((item, idx) => {
              return (
                <ResidencyCard
                  data={item}
                  key={idx}
                  idx={idx}
                  category={item.category}
                  selectedIdx={selectedIdx}
                  name={currentData.name}
                  src={item.resize || item.img}
                  userAgent={userAgent}
                  CardClicked={ImgCardClicked}
                  imgMouseOver={imgMouseOver}
                />
              );
            })}
            <Infinite PagingUpdate={PagingUpdate} />
          </div>
        </div>
      </section>
      {changeImg && userAgent !== "mb" && (
        <ResidencyDetailContainer
          name={currentData.name}
          subject={currentData.category || ""}
          data={currentData?.list || []}
          id={currentData.id}
          changeImg={changeImg}
          CloseModal={CloseModal}
        />
      )}
    </main>
  );
};

export default Residency;
