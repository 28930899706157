import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
import { formatDate } from "../../lib/lib";
type Props = {
  title: string;
  subject: string;
  img: string;
  period: {
    start: number;
    finish: number;
  };
  place: string;
  host: string;
  userAgent: UserAgentType;
};

const ProjectDetailTitle = ({
  title,
  subject,
  img,
  period,
  place,
  host,
  userAgent,
}: Props) => {
  const dataArr = [
    {
      key: "행사명",
      value: title,
    },
    {
      key: "기간",
      value: `${formatDate(period.start)} ~ ${formatDate(period.finish)}`,
    },
    {
      key: "장소",
      value: place,
    },
    {
      key: "주최",
      value: host,
    },
  ];
  return (
    <div className="project-detail-title-box">
      <div className="title-box">
        <p className="title">{title}</p>
        <p className="subtitle">{subject}</p>
      </div>
      <div className="main-img-box">
        <img className="img" src={img} alt="" />
        <div className="info-wrapper">
          {dataArr.map(({ key, value }, idx) => (
            <div className="info-text-wrapper" key={idx}>
              <p className="info-title">{key}</p>
              <p className="info-value">{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailTitle;
