import React, { useCallback, useEffect, useRef, useState } from "react";
import ResidencySlider from "../ResidencySlider";
import { useDispatch, useSelector } from "react-redux";
import { ChangeImg } from "../../../store/config/config";
import { RootState } from "../../../store";
import { ResidencyListType } from "resdiency";

type Props = {
  data: {
    caption: string;
    img: string;
    timestamp: number;
    title: string;
  }[];
  name: string;
  subject: string;
};

const ResidencySliderContainer = ({ data, name, subject }: Props) => {
  const dispatch = useDispatch();
  const crtImg = useSelector<RootState, ResidencyListType | undefined>(
    (state) => state.config.img
  );
  const imgRef = useRef<HTMLImageElement>(null);
  const sliderBoxRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slideWidth, setSlideWidth] = useState<number>(0);

  const PrevBtn = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const NextBtn = () => {
    if (currentSlide < data.length - 1) {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const ImgClicked = useCallback(
    (img: ResidencyListType) => {
      dispatch(ChangeImg(img));
    },
    [dispatch]
  );

  useEffect(() => {
    if (imgRef.current) {
      setSlideWidth(imgRef.current.clientWidth + 10);
    }
  }, []);
  return (
    <ResidencySlider
      data={data}
      sliderBoxRef={sliderBoxRef}
      imgRef={imgRef}
      currentSlide={currentSlide}
      slideWidth={slideWidth}
      name={name}
      crtImg={crtImg}
      subject={subject}
      PrevBtn={PrevBtn}
      NextBtn={NextBtn}
      ImgClicked={ImgClicked}
    />
  );
};

export default ResidencySliderContainer;
